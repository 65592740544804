import styles from './styles.module.scss'
import clsx from 'clsx'

type TContainer = {
  children: React.ReactNode
}

export const Container = (props: TContainer) => {
  const {children} = props
  return <section className={clsx(styles['container'])}>{children}</section>
}
