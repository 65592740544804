import {Container} from '@/components/container/Container'
import styles from './styles.module.scss'
import {RectShape} from 'react-placeholder/lib/placeholders'

export const DesktopPlaceholder = () => {
  return (
    <Container>
      <div className='mt-4'>
        <PlaceHolder />
      </div>
      <BannerPlaceHolder />
    </Container>
  )
}

export const BannerPlaceHolder = () => (
  <div className={styles['banner_placeholders']} style={{gap: 20}}>
    <RectShape className={styles['banner_placeholder']} color='#e6e6e6' />
    <RectShape className={styles['banner_placeholder']} color='#e6e6e6' />
    <RectShape className={styles['banner_placeholder']} color='#e6e6e6' />
  </div>
)

const StoryPlaceHolder = () => {
  return (
    <div className={styles['story_placeholders']}>
      <RectShape className={styles['story_placeholder']} color='#e6e6e6' />
      <RectShape className={styles['story_placeholder_text']} color='#e6e6e6' />
    </div>
  )
}

export const PlaceHolder = () => {
  return (
    <div className={styles['story_placeholder_container']}>
      {Array.from({length: 11}).map((_, index) => (
        <StoryPlaceHolder key={index} />
      ))}
    </div>
  )
}
